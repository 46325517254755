import { SurveyResult } from '@/models/Survey';

const config = require('Config');

export const saveSurvey = (surveyResult: SurveyResult): Promise<Response> => {
  const surveyTagsList = surveyResult.surveyTags;
  const surveyResultsData = { ...surveyResult, surveyTags: surveyTagsList };
  const surveySaveEndpoint = `${config.expertApiUrl}/surveys`; // update this
  return fetch(
    surveySaveEndpoint,
    {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify(surveyResultsData),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};
