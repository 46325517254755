import Vue from 'vue';
import Router from 'vue-router';
import Survey from '@/components/Survey.vue'; // @ is an alias to /src

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:partner/:sessionId/:surveyKey/:chatResolvedAt',
      name: 'Survey',
      component: Survey,
    },
  ],
});
