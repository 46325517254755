








import Vue from 'vue';
import SurveyView from '@/views/SurveyView.vue';
import SurveyFinishedView from '@/views/SurveyFinishedView.vue';
import SurveyExpiredView from '@/views/SurveyExpiredView.vue';
import { SurveyData } from '@/models/Survey';
import { getSurveyConfig } from '@/clients/tweekClient';

export default Vue.component('Survey', {
  data: (): SurveyData => ({
    done: false,
    surveyConfig: undefined,
  }),
  components: {
    SurveyView,
    SurveyFinishedView,
    SurveyExpiredView
  },
  computed: {
    expired(): boolean {
      if (!this.surveyConfig) {
        return false;
      }

      const chatResolvedAt: number = Date.parse(this.$route.params.chatResolvedAt);
      return Date.now() - chatResolvedAt > this.surveyConfig.expiration * 1000;
    }
  },
  async beforeMount(): Promise<void> {
    this.surveyConfig = await getSurveyConfig();
  }
});
