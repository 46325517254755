




import Vue from 'vue';

export default Vue.component('Comments', {
  methods: {
    handleCommentUpdated(evt): void {
      this.$emit('update:comment', evt.target.value);
    }
  }
});
