








import Vue from 'vue';
import ExpirationClock from '@/images/ExpirationClock.vue';

export default Vue.extend({
  name: 'SurveyExpiredView',
  components: {
    ExpirationClock
  }
});
