






import Vue from 'vue';
import { track } from '@/clients/mixpanelClient';

export default Vue.component('StarRating', {
  props: {
    rating: Number
  },
  methods: {
    handleRating(evt, rating: number): void {
      this.$emit('update:rating', rating);
    }
  }
});
