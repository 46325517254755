








import Vue from 'vue';
import { Tag, TagsData } from '@/models/Tags';
import { shuffleArray } from '@/utils/shuffle';
import { track } from '@/clients/mixpanelClient';

export default Vue.component('Tags', {
  props: {
    clickableTags: Array as () => Tag[]
  },
  methods: {
    handleClick(evt, tag: Tag): void {
      this.$emit('update:tag', tag);
    }
  }
});
