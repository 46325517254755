import { render, staticRenderFns } from "./SurveyExpiredView.vue?vue&type=template&id=eda1f940&scoped=true&"
import script from "./SurveyExpiredView.vue?vue&type=script&lang=ts&"
export * from "./SurveyExpiredView.vue?vue&type=script&lang=ts&"
import style0 from "./SurveyExpiredView.vue?vue&type=style&index=0&id=eda1f940&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eda1f940",
  null
  
)

component.options.__file = "SurveyExpiredView.vue"
export default component.exports