import { render, staticRenderFns } from "./Survey.vue?vue&type=template&id=33b9ad3c&"
import script from "./Survey.vue?vue&type=script&lang=ts&"
export * from "./Survey.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Survey.vue"
export default component.exports