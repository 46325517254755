import { SurveyConfig } from '@/models/Survey';
const config = require('Config');

const defaultSurveyConfig: SurveyConfig = {
  header: {
    default: 'How did expertName do?'
  },
  subheader: {
    default: {
      positive: 'What was awesome?',
      negative: 'What could be better?'
    }
  },
  tags: {
    default: {
      positive: ['Patience', 'Always there to chat', 'Easy to understand', 'Solved my problem quickly'],
      negative: ['Pick up sooner', 'Explain more clearly', 'Follow up faster', 'Troubleshoot more efficiently']
    }
  },
  ratingExplanations: {
    default: ['Flag this chat', 'Disappointing', 'Below average', 'Ok, could be better', 'Awesome!']
  },
  expiration: 28800 // 8 hours
};

export const getSurveyConfig = async (): Promise<SurveyConfig> => {
  const surveyConfigEndpoint = `${config.tweekUrl}/home/agent/survey/_`;
  return await fetch(surveyConfigEndpoint)
    .then(async response => {
      const surveyConfig = await response.json();
      const values = {};
      Object.keys(surveyConfig).forEach(key => {
        const normalKey = key.replace(/_(.)/g, (_, c) => c.toUpperCase());
        values[normalKey] = surveyConfig[key];
      });
      return values as SurveyConfig;
    })
    .catch(() => defaultSurveyConfig) as SurveyConfig;
};
