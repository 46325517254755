const mixpanel = require('mixpanel-browser');
const config = require('Config');

const mixpanelToken = config.mixpanelToken;
mixpanel.init(config.mixpanelToken);

export const track = (name: string, properties?: {}): Promise<any> => {
  const promise = new Promise((resolve, reject) => {
    mixpanel.track(`StarSurvey_${name}`, properties, (status, data) => {
      if (status === 0) {
        reject(data);
      } else {
        resolve(data);
      }
    });
  });
  return promise;
};
