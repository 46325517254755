import { render, staticRenderFns } from "./SurveyView.vue?vue&type=template&id=5dad5d75&scoped=true&"
import script from "./SurveyView.vue?vue&type=script&lang=ts&"
export * from "./SurveyView.vue?vue&type=script&lang=ts&"
import style0 from "./SurveyView.vue?vue&type=style&index=0&id=5dad5d75&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dad5d75",
  null
  
)

component.options.__file = "SurveyView.vue"
export default component.exports