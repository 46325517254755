












import Vue from 'vue';
import StarRating from '@/components/StarRating.vue';
import Tags from '@/components/Tags.vue';
import Comments from '@/components/Comments.vue';
import GenericThumb from '@/images/GenericThumb.vue';
import { SurveyViewData, SurveyConfig, SurveyResult } from '@/models/Survey';
import { Tag } from '@/models/Tags';
import { shuffleArray } from '@/utils/shuffle';
import { saveSurvey } from '@/clients/surveyClient';
import { track } from '@/clients/mixpanelClient';
import { setTimeout, clearTimeout } from 'timers';

export default Vue.extend({
  name: 'SurveyView',
  props: {
    surveyConfig: Object as () => SurveyConfig
  },
  data: (): SurveyViewData => ({
    rating: 0,
    comments: '',
    clickableTags: [],
    saveTimer: undefined,
    done: false
  }),
  computed: {
      positive(): boolean {
        return this.rating >= 5;
      },
      negative(): boolean {
        return this.rating > 0 && this.rating < 5;
      },
      rated(): boolean {
        return this.rating !== 0;
      },
      sessionId(): string {
        return this.$route.params.sessionId;
      },
      expertBrandName(): string {
        const partner: string = this.$route.params.partner;
        return `your ${partner.toLowerCase() === 'verizon' ? 'Tech Coach' : 'Expert'}`;
      },
      surveyKey(): string {
        const key: string = this.$route.params.surveyKey;
        return key === 'na' ? 'default' : key;
      },
      tags(): string[] {
        const { surveyConfig, surveyKey, positive } = this;
        if (!surveyConfig) {
          return [];
        }

        return surveyConfig.tags[surveyKey][positive ? 'positive' : 'negative'];
      },
      surveyHeader(): string {
        if (!this.surveyConfig) {
          return '';
        }

        const tweekHeader: string = this.surveyConfig.header[this.surveyKey];
        return tweekHeader.replace('expertName', this.expertBrandName);
      },
      surveySubHeader(): string {
        if (!this.surveyConfig) {
          return '';
        }

        const {surveyConfig, surveyKey, positive} = this;
        const tweekSubHeader: string = this.surveyConfig.subheader[surveyKey][positive ? 'positive' : 'negative'];
        return tweekSubHeader.replace('expertName', this.expertBrandName);
      },
      ratingComment(): string {
        const {surveyKey, surveyConfig: {ratingExplanations}, rating} = this;
        const explanations: string[] = ratingExplanations[surveyKey] || ratingExplanations.default;
        return explanations[rating - 1];
      }
    },
    watch: {
      surveyConfig() {
        this.generateClickableTags();
      },
      positive() {
        this.generateClickableTags();
      }
    },
    methods: {
      updateRating(rating: number): void {
        this.rating = rating;
        this.trackAnalytics('Rating_Updated', { rating });
        this.updateSurvey();
      },
      updateComment(comment: string): void {
        this.comments = comment;
        this.trackAnalytics('Comment_Updated', { comment });
        this.updateSurvey();
      },
      updateTag(tag: Tag): void {
        tag.value = !tag.value;
        this.trackAnalytics('Tag_Clicked', { tag });
        this.updateSurvey();
      },
      async handleDone(): Promise<void> {
        this.done = true;
        this.$emit('update:done');
        this.trackAnalytics('Done_Clicked');
        this.saveSurvey();
      },
      generateClickableTags() {
        this.clickableTags = shuffleArray<string>(this.tags)
          .map((tag: string): Tag => ({key: tag, value: false}));
      },
      updateSurvey(): void {
        if (this.saveTimer) {
          clearTimeout(this.saveTimer);
          this.saveTimer = undefined;
        }

        this.saveTimer = setTimeout(this.saveSurvey, this.surveyConfig.surveySaveTimeoutDuration || 1000);
      },
      saveSurvey(): void {
        const { rating, comments, done, sessionId, clickableTags, surveyConfig } = this;
        const surveyResult: SurveyResult = {
          rating,
          comments,
          sessionId,
          isCompleted: done,
          surveyTags: {
            tags: clickableTags
          }
        };

        try {
          saveSurvey(surveyResult)
            .then(response => {
                if (!response.ok) {
                  throw response;
                }

                this.trackAnalytics('Survey_Save_Success', surveyResult);
              }
            )
            .catch(error => this.trackAnalytics('Survey_Save_Fail', { surveyResult, error }));
        } catch (error) {
          this.trackAnalytics('Survey_Save_Fail', { surveyResult, error });
        }
      },
      trackAnalytics(name: string, properties: {}) {
        track(name, {sessionId: this.sessionId, surveyKey: this.surveyKey, ...properties});
      }
    },
    components: {
      StarRating,
      Tags,
      Comments,
      GenericThumb
    },
    mounted() {
      this.trackAnalytics('Loaded');
    }
});
